const NotFound = () => {
  return (
    <div className="bg-gray-100 flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          잘못된 경로입니다.
        </h1>
        <p className="text-lg text-gray-700 mb-4">도메인을 확인해주세요!</p>
        <a
          href="/"
          className="inline-block px-4 py-2 text-blue-700 hover:text-red-700 border-b-2 border-blue-700 hover:border-red-700 transition-colors duration-200"
        >
          홈으로 돌아가기
        </a>
      </div>
    </div>
  );
};

export default NotFound;
